<template>
  <b-row>
    <b-col cols="12" v-if="can('read/pengeluaran-rill')">
      <b-card title="Pengeluaran Rill">
        <b-row>
          <b-col class="mb-1">
            <b-button
              v-if="can('store/pengeluaran-rill')"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="ml-0"
              @click="gotoCreatePage()"
            >
              Tambah
            </b-button>
          </b-col>
        </b-row>

        <b-row class="pt-1 mb-1">
          <b-col md="9">
            <b-button-group class="d-none d-lg-inline-flex filter-status-container">
              <b-button
                :variant="status_id === null ? 'primary' : 'outline-primary'"
                @click="filterStatus(null)"
              >
                Semua
              </b-button>
              <b-button
                v-for="(item, idx) in status"
                @click="filterStatus(item.id)"
                :variant="status_id === item.id ? 'primary' : 'outline-primary'"
                :key="idx"
                >{{ item.name }}</b-button
              >
            </b-button-group>
            <div class="d-block d-lg-none mb-1">
              <b-form-select
                v-model="status_id"
                :options="statusList"
              ></b-form-select>
            </div>
          </b-col>

          <b-col md="3" xs="12">
            <b-form @submit.prevent="handleSearch">
              <b-input-group size="8">
                <b-form-input
                  id="filterInput"
                  v-model="search"
                  type="search"
                  :placeholder="'Cari disini...'"
                />
                <b-input-group-append>
                  <b-button :disabled="!search" @click="search = ''">
                    Hapus
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form>
          </b-col>
        </b-row>

        <b-table
          striped
          hover
          responsive
          show-empty
          class="position-relative"
          primary-key="email"
          :per-page="perPage"
          :current-page="currentPage"
          :items="getItems"
          :fields="fields"
          ref="tableFe"
        >
          <template #empty>
            <div class="text-center my-2">Data tidak tersedia</div>
          </template>
          <template #emptyfiltered>
            <div class="text-center my-2">Data tidak tersedia</div>
          </template>

          <template #cell(no)="data">
            {{ (currentPage - 1) * perPage + (data.index + 1) }}
          </template>

          <template #cell(name)="data">
            {{ data.item.nippos }} / {{ data.item.name }}
          </template>

          <template #cell(total_days)="data">
            {{ data.item.total_days + ' hari' }}
          </template>

          <template #cell(facility)="data">
            <template v-if="data.item.sppd_facility_type_id == 1"
              >Perjalanan Dinas Biasa</template
            >
            <template v-else-if="data.item.sppd_facility_type_id == 2"
              >Perjalanan Dinas Fasilitas Khusus</template
            >
            <template v-else>-</template>
          </template>

          <!-- A virtual column -->
          <template #cell(Aksi)="data">
            <div style="min-width: 270px">
              <b-button
                v-if="
                  data.item.status_real_expense == 0 ||
                  data.item.status_real_expense == 1 ||
                  data.item.status_real_expense == 3
                "
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-popover.hover.bottom="'Print'"
                variant="primary"
                size="sm"
                class="btn-icon float-sm-left mr-1"
                @click="print(data.item.id, data.item.sppd_number)"
              >
                <feather-icon icon="PrinterIcon" />
              </b-button>

              <b-button
                v-if="
                  data.item.status_real_expense == 0 &&
                  can('approval_admin/pengeluaran-rill')
                "
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                v-b-popover.hover.bottom="'Approve'"
                size="sm"
                class="btn-icon float-sm-left mr-1"
                @click="showapproval1(data.item.id)"
              >
                <feather-icon icon="CheckIcon" />
              </b-button>

              <b-button
                v-if="
                  data.item.status_real_expense == 1 &&
                  can('approval_pa/pengeluaran-rill')
                "
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                v-b-popover.hover.bottom="'Approve'"
                size="sm"
                class="btn-icon float-sm-left mr-1"
                @click="showapproval2(data.item.id)"
              >
                <feather-icon icon="CheckIcon" />
              </b-button>

              <b-button
                v-if="can('show/pengeluaran-rill')"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="info"
                v-b-popover.hover.bottom="'Detail'"
                size="sm"
                class="btn-icon float-sm-left mr-1"
                @click="gotoDetailPage(data.item.id)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>

              <b-button
                v-if="
                  data.item.status_real_expense == 0 &&
                  can('edit/pengeluaran-rill')
                "
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                v-b-popover.hover.bottom="'Ubah'"
                size="sm"
                class="btn-icon float-sm-left mr-1"
                @click="gotoEditPage(data.item.id)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </div>
          </template>
        </b-table>

        <span v-if="totalRows == 1" style="margin: 1rem"></span>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- page length -->
          <b-form-group
            label="Per Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              @change="changePerPage()"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
            <!-- Modal -->
            <b-modal
              v-model="show1"
              id="modal-default"
              ref="my-modals"
              title="Approval Admin"
              size="md"
              cancel-variant="outline-secondary"
            >
              <div class="hidden-scrollbar">
                <div class="inner">
                  <b-form>
                    <b-row class="mb-1 mr-0">
                      <b-col md="12" class="mb-1">
                        <b-form-group>
                          <label label-for="note">Catatan</label>
                          <b-form-textarea
                            id="mc-name"
                            rows="3"
                            placeholder="Masukkan Catatan"
                            v-model="model.note"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Status" label-for="mc-status">
                          <b-form-radio-group
                            v-model="model.status"
                            :options="statusList1"
                            class="mt-1"
                            value-field="value"
                            text-field="label"
                          />
                          <p class="fs-6 text-danger" v-if="model.status == ''">
                            {{ errors }}
                          </p>
                          <p v-else-if="model.status != ''"></p>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-form>
                </div>
              </div>
              <template #modal-footer>
                <div class="w-100">
                  <b-button
                    variant="primary"
                    size="md"
                    class="float-right btn btn-primary mr-1"
                    @click="approved()"
                  >
                    Kirim
                  </b-button>
                  <b-button
                    variant="outline-secondary"
                    size="md"
                    class="float-right btn btn-outline-secondary mr-1"
                    @click="closeapproval1()"
                  >
                    Batal
                  </b-button>
                </div>
              </template>
            </b-modal>
            <b-modal
              v-model="show2"
              id="modal-default"
              ref="my-modals"
              title="Approval Manajer Ruta"
              size="md"
              cancel-variant="outline-secondary"
            >
              <div class="hidden-scrollbar">
                <div class="inner">
                  <b-form>
                    <b-row class="mb-1 mr-0">
                      <b-col md="12" class="mb-1">
                        <b-form-group>
                          <label label-for="note">Catatan</label>
                          <b-form-textarea
                            id="mc-name"
                            rows="3"
                            placeholder="Masukkan Catatan"
                            v-model="model.note"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Status" label-for="mc-status">
                          <b-form-radio-group
                            v-model="model.status"
                            :options="statusList2"
                            class="mt-1"
                            value-field="value"
                            text-field="label"
                          />
                          <p class="fs-6 text-danger" v-if="model.status == ''">
                            {{ errors }}
                          </p>
                          <p v-else-if="model.status != ''"></p>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-form>
                </div>
              </div>
              <template #modal-footer>
                <div class="w-100">
                  <b-button
                    variant="primary"
                    size="md"
                    class="float-right btn btn-primary mr-1"
                    @click="approved()"
                  >
                    Kirim
                  </b-button>
                  <b-button
                    variant="outline-secondary"
                    size="md"
                    class="float-right btn btn-outline-secondary mr-1"
                    @click="closeapproval2()"
                  >
                    Batal
                  </b-button>
                </div>
              </template>
            </b-modal>
          </div>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col v-else>
      <b-card>
        <div class="col-12 p-2 p-sm-3 mb-4 mt-3">
          <div class="w-100 text-center">
            <h2 class="mb-1">Tidak Memiliki Akses</h2>
            <p class="mb-2">Anda tidak memiliki akses pada halaman ini</p>
          </div>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<style>
.width160px {
  width: 160px !important;
  background-color: 'red !important';
}
</style>

<script>
import {
  BRow,
  BCol,
  BTable,
  BCard,
  BButton,
  BButtonGroup,
  BInputGroup,
  BFormRadioGroup,
  BForm,
  BFormInput,
  BFormTextarea,
  BInputGroupAppend,
  BCardBody,
  BFormGroup,
  BFormSelect,
  BPagination,
  VBPopover,
  BFormCheckbox,
} from 'bootstrap-vue'
import TableFe from '@/views/table/bs-table/TableFE'
import axios from '@axios'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'SppdList',
  components: {
    BRow,
    BCol,
    BTable,
    BCard,
    TableFe,
    BButton,
    BButtonGroup,
    BInputGroup,
    BFormRadioGroup,
    BForm,
    BFormInput,
    BFormTextarea,
    BInputGroupAppend,
    BCardBody,
    BFormGroup,
    BFormSelect,
    BPagination,
    VBPopover,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    'b-popover': VBPopover,
  },
  data() {
    return {
      config: {
        api: '/real-expenses',
        status: '/misc/status-real-expense',
      },
      errors: '',
      model: {
        note: '',
        status: '',
      },
      show1: false,
      show2: false,
      search: '',
      perPage: 10,
      currentPage: 1,
      totalRows: 10,
      sortBy: 'id',
      pageOptions: [1, 10, 25, 50, 100],
      fields: [
        'no',
        { key: 'sppd_number', label: 'No SPPD' },
        { key: 'name', label: 'NIPPOS / Nama', thStyle: 'min-width:200px' },
        { key: 'total_days', label: 'Lama Perjalanan' },
        { key: 'destination_office', label: 'Tujuan' },
        { key: 'submission_date', label: 'Tanggal Pengajuan' },
        { key: 'status', label: 'Status' },
        { key: 'Aksi', label: 'Aksi', thStyle: 'width:290px' },
      ],
      items: [],
      status: [],
      statusList: [
        { value: null, text: 'Semua' },
        { value: 0, text: 'Menunggu Approval Admin' },
        { value: 1, text: 'Menunggu Approval Manajer Ruta' },
        { value: 2, text: 'Ditolak Admin' },
        { value: 3, text: 'Disetujui' },
        { value: 4, text: 'Ditolak' },
      ],
      statusList1: [
        {
          label: 'Setujui',
          value: 1,
        },
        {
          label: 'Tolak',
          value: 2,
        },
      ],
      statusList2: [
        {
          label: 'Setujui',
          value: 3,
        },
        {
          label: 'Tolak',
          value: 4,
        },
      ],
      list_id: '',
      status_id: null,
    }
  },
  watch: {
    status_id: function () {
      this.$refs.tableFe.refresh()
    },
    search: function () {
      this.$refs.tableFe.refresh()
    },
  },
  mounted() {
    let _ = this
    _.getStatus()
    if (_.$route.params) {
      if (_.$route.params.event) {
        _.makeToast(
          _.$route.params.event,
          _.$route.params.title,
          _.$route.params.text,
        )
      }
    }
  },
  methods: {
    print(id, sppdNumber) {
      let _ = this
      axios({
        url: _.config.api + '/' + id + '/print',
        method: 'GET',
        responseType: 'blob',
      }).then(response => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]))
        var fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute(
          'download',
          'pengajuan-rill-' + sppdNumber + '.pdf',
        )
        document.body.appendChild(fileLink)

        fileLink.click()
      })
    },
    showapproval1(id) {
      let _ = this
      _.list_id = id
      _.show1 = true
    },
    closeapproval1() {
      let _ = this
      _.show1 = false
      _.$refs.tableFe.refresh()
    },
    showapproval2(id) {
      let _ = this
      _.list_id = id
      _.show2 = true
    },
    closeapproval2() {
      let _ = this
      _.show2 = false
      _.$refs.tableFe.refresh()
    },
    approved() {
      const url = `${this.config.api}/${this.list_id}/status`
      const data = { status: this.model.status, note: this.model.note }
      if (this.model.status != '') {
        axios
          .put(url, data)
          .then(() => {
            this.$swal({
              icon: 'success',
              title: 'Status berhasil diperbarui',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.show1 = false
            this.show2 = false
            this.$refs.tableFe.refresh()
          })
          .catch(err => {
            this.$swal({
              icon: 'Danger',
              title: 'Terjadi kesalahan!',
              text: err.message,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
      } else {
        this.errors = 'Status wajib diisi'
      }
    },
    getStatus() {
      let _ = this
      axios.get(_.config.status).then(response => {
        let _ = this
        _.status = response.data.data
      })
    },
    gotoDetailPage(id) {
      let _ = this
      _.$router.push('/pengeluaran-rill/' + id + '/detail')
    },
    gotoEditPage(id) {
      let _ = this
      window.location = '/pengeluaran-rill/' + id + '/edit'
    },
    filterStatus(id) {
      let _ = this
      _.status_id = id
      _.$refs.tableFe.refresh()
    },
    getItems(ctx, callback) {
      const _ = this

      let endpoint = `${_.config.api}?per_page=${_.perPage}&page=${_.currentPage}`
      if (_.status_id != null) {
        endpoint += `&status=${_.status_id}`
      }
      if (_.search) {
        endpoint += `&key=${_.search}`
      }
      axios
        .get(endpoint)
        .then(resp => {
          const consume = resp.data.data
          _.perPage = consume.per_page
          _.currentPage = consume.current_page
          _.totalRows = consume.total
          callback(consume.data || [])
        })
        .catch(err => {
          console.log(err)
        })
      return null
    },
    handleSearch() {
      this.$refs.tableFe.refresh()
    },
    changePerPage() {
      const _ = this
      _.$refs.tableFe.refresh()
    },
    changeCurrentPage(currentPage) {
      let _ = this
      _.currentPage = currentPage
    },
    makeToast(variant = null, title = 'Title here...', text = 'Text here...') {
      this.$bvToast.toast(text, {
        title,
        variant,
        solid: true,
      })
    },
    gotoCreatePage() {
      let _ = this
      _.$router.push('/pengeluaran-rill/add')
    },
  },
}
</script>

<style>
.b-popover {
  font-size: 10px;
}
@media screen and (max-width: 1440px) {
  .filter-status-container button {
    font-size: 12px;
  }
}
</style>
